export default {
  state: {
    window_list: [],
    called_list: [],
    calling_tickets: []
  },
  mutations: {
    SET_WINDOW_LIST(state, payload) {
      state.window_list = payload
    },
    SET_CALLED_LIST(state, payload) {
      state.called_list = payload.reverse()
    },
    SET_CALLING_TICKETS(state, payload) {
      state.calling_tickets.push(payload)
    },
    REMOVE_CALLING_TICKET(state) {
      state.calling_tickets.shift()
    }
  },
  actions: {
    socket_call_ticket({ commit, rootState }, payload) {
      if (rootState.system.init) {
        commit('SET_WINDOW_LIST', payload.window_list)
        commit('SET_CALLED_LIST', payload.called_list)

        let calling_ticket = payload.calling
        calling_ticket.call_datetime = new Date()
        calling_ticket.audio = payload.audio
        calling_ticket.delay = payload.delay
        commit('SET_CALLING_TICKETS', calling_ticket)
      }
    }
  }
}
