<template>
  <v-app>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({}),
  created() {
    this.$socket.emit('register', { token: process.env.VUE_APP_QUEUE_SOCKET_SERVER_REGISTER_TOKEN })
  }
}
</script>

<style>
.v-application {
  font-family: PingFangHK-Regular, sans-serif !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
