import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue')
  },
  {
    path: '/error/:language',
    name: 'Error',
    component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue')
  },
  {
    path: '/tv',
    name: 'TV',
    component: () => import(/* webpackChunkName: "TV" */ '../views/TV.vue')
  },
  {
    path: '/ticket/:language',
    name: 'TicketQuery',
    component: () => import(/* webpackChunkName: "TicketQuery" */ '../views/TicketQuery.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // avoid redirect to same page
  if (to === from) {
    return
  }

  // Prevent user access to others page
  if (to.path === '/') return next('/tv')

  next()
})

export default router
