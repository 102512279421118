// Module loader - auto load the module which meet the naming rule
const requireModule = require.context('./', true, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  // Example:
  // Folder structure -> ./global/auth/login.js
  // rootFolder = global
  // masterFolder = auth
  // fileName = login.js
  const fileList = fileName.split('/')
  if (fileList.length === 4) {
    const rootFolder = fileList[1]
    const masterFolder = fileList[2]

    if (fileName === `./${rootFolder}/${masterFolder}/index.js`) {
      const moduleName = masterFolder
      modules[moduleName] = {
        ...requireModule(fileName).default
      }
    }
  }
})
export default modules
