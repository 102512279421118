import axios from 'axios'

export default {
  state: {
    init: false,
    exchange_rate: {
      datetime: null,
      rate: null
    }
  },
  mutations: {
    SET_SYSTEM_INIT(state, payload) {
      state.init = payload
    },
    SET_SYSTEM_EXCHANGE_RATE(state, { datetime, rate }) {
      state.exchange_rate.datetime = datetime
      state.exchange_rate.rate = rate
    }
  },
  actions: {
    async getExchangeRate({ commit }) {
      try {
        const response = await axios.post(process.env.VUE_APP_EXCHANGE_RATE_ENDPOINT)
        if (response.data.success) {
          commit('SET_SYSTEM_EXCHANGE_RATE', response.data.data)
        } else throw Error(response.data.message)
      } catch (error) {
        console.log(error.message)
      }
    }
  }
}
