// Module loader - auto load the module which meet the naming rule
const requireModule = require.context('./', true, /\.js$/)
const i18n = {}

requireModule.keys().forEach(fileName => {
  const fileList = fileName.split('/')
  if (fileList.length === 3) {
    const rootFolder = fileList[1]

    if (fileName === `./${rootFolder}/index.js`) {
      i18n[rootFolder] = {
        ...requireModule(fileName).default
      }
    }
  }
})

export default i18n
