import { zhHant } from 'vuetify/es5/locale'

// Usage -> {{ $vuetify.lang.t('$vuetify.close') }} -> 关闭
export default {
  ...zhHant,
  ticket_query: {
    center_name: '友邦客戶服務中心',
    address_1: '香港北角電氣道183號',
    address_2: '友邦廣場12樓友邦財駿中心',
    ticket_date_time: '取票日期及時間 {0}',
    waiting_count_1: '您前面有',
    waiting_count_2: '位客人在輪候',
    update_time: '以上為 {0} 之狀況',
    refresh_prompt: '請刷新頁面以獲取最新資訊',
    app_title_1: 'AIA Connect 友聯繫',
    app_title_2: '簡易連結你需要',
    app_description: '一個流動程式管理您所有友邦賬戶，包括個人保險、團體保險、強積金/公積金/澳門退休金及「AIA Vitality 健康程式」。',
    download: '下載「AIA友聯繫」',
    expire_title_1: '閣下所持之票號已過。',
    expire_title_2: '如有需要, 請重新取票輪候。',
    expire_title_3: '多謝!'
  }
}
