import { en } from 'vuetify/es5/locale'

// Usage -> {{$vuetify.lang.t('$vuetify.close')}} -> 關閉
export default {
  ...en,
  ticket_query: {
    center_name: 'AIA Customer Service Centre',
    address_1: '183 Electric Road, North Point, Hong Kong',
    address_2: '12/F AIA Tower in North Point',
    ticket_date_time: 'Ticket Date and Time {0}',
    waiting_count_1: 'There are',
    waiting_count_2: 'guests waiting in front of you',
    update_time: 'The above information is situation at {0}',
    refresh_prompt: 'Please refresh the page to get the latest information',
    app_title_1: 'AIA Connect',
    app_title_2: 'Easy to Use. Easy to Connect. Easy to Transact.',
    app_description: 'One app, one Easy Login ID to access all your AIA accounts, from individual life insurance, group insurance, MPF/ORSO/Macau Pension to AIA Vitality.',
    download: 'Download',
    expire_title_1: 'This ticket number has expired.',
    expire_title_2: 'Please kindly get a new ticket to wait for our service when necessary.',
    expire_title_3: 'Thank you!'
  }
}
