import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import lang from '@/lang'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: { zhHant: lang.zhHant, zhHans: lang.zhHans, en: lang.en },
    current: 'zhHant'
  },
  icons: {
    iconfont: 'mdi' || 'fa'
  }
}

export default new Vuetify(opts)
