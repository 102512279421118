import { zhHans } from 'vuetify/es5/locale'

// Usage -> {{ $vuetify.lang.t('$vuetify.close') }} -> 关闭
export default {
  ...zhHans,
  ticket_query: {
    center_name: '友邦客户服务中心',
    address_1: '香港北角电气道183号',
    address_2: '友邦广场12楼友邦财骏中心',
    ticket_date_time: '取票日期及时间 {0}',
    waiting_count_1: '您前面有',
    waiting_count_2: '位客人在轮候',
    update_time: '以上为 {0} 之状况',
    refresh_prompt: '请刷新页面以获取最新资讯',
    app_title_1: 'AIA Connect 友联系',
    app_title_2: '简易连结你需要',
    app_description: '一个流动程式管理您所有友邦账户，包括个人保险、团体保险、强积金/公积金/澳门退休金及「AIA Vitality 健康程式」。 ',
    download: '下载「AIA友联系」',
    expire_title_1: '阁下所持之票号已过。',
    expire_title_2: '如有需要, 请重新取票轮候。',
    expire_title_3: '多谢！'
  }
}
