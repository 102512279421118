// Import Vue core modules
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Import UI Framework
import vuetify from './plugins/vuetify'

// Import libraries modules
import api from '@/apis'
import moment from 'moment'
import VueSocketIO from 'vue-socket.io'

// Modules init
Vue.config.productionTip = false
Object.defineProperty(Vue.prototype, '$api', { value: api })
Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_QUEUE_SOCKET_SERVER_ENDPOINT,
    vuex: {
      store,
      actionPrefix: 'socket_',
      mutationPrefix: 'SOCKET_'
    }
  })
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
