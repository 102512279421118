import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_SERVER_ENDPOINT
})

// Request interceptor
instance.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.error('error:', error)
    Promise.reject(error)
  }
)

// Response interceptor
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.error('error:' + error)
    return Promise.reject(error)
  }
)

export default instance
