import Vuetify from '@/plugins/vuetify'

export default {
  state: {
    current_lang: Vuetify.framework.lang.current
  },
  mutations: {
    SET_I18N_CURRENT_LANGUAGE(state, payload) {
      Vuetify.framework.lang.current = payload
      state.current_lang = payload
    }
  }
}
