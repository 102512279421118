// Import Vue Core Modules
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Import Module Loader
import modules from './module_loader'

export default new Vuex.Store({
  modules
})
